<template>
    <v-container fluid>
        <v-form @submit.prevent="getFaqs">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'faq.create'}" color="indigo" dark fab fixed
                   top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <h3 class="headline">{{ $t('search_faq_question') }}</h3>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'faq.create'}" dark color="indigo">{{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="10">
                                <ValidationProvider ref="name" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="name" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-comment-question"
                                                  :label="$t('faq_question_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="2">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                    <v-data-table item-key="id" ref="table" :headers="headers" :items="faqItems"
                                  :options.sync="options" disable-pagination
                                  :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                                  :loading="loading" :locale="lang" :loading-text="$t('loading_please_wait')"
                                  class="sortable-table elevation-1" hide-default-footer>
                    <template v-slot:item.sort>
                        <v-btn icon class="handle">
                            <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.question="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.question }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editFaq(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :disabled="item.deleted" @click="deleteFaq(item)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>

        </v-card>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import Sortable from "sortablejs";

    export default {
        name: "Faqs",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        inject: ['forceRerender'],
        data() {
            return {
                name: null,
                sortBy: "sort",
                sortDir: false,
                loading: false,
                options: {},
                faqItems: [],
                headers: [
                    {
                        text: "",
                        align: "left",
                        sortable: false,
                        value: "sort",
                    },
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: false,
                        value: "question"
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
                type: null,
                active: 1,
                activeItems: [
                    {
                        id: -1,
                        name: this.$t('all')
                    },
                    {
                        id: 1,
                        name: this.$t('yes')
                    },
                    {
                        id: 2,
                        name: this.$t('no')
                    }
                ],
            };
        },
        computed: {
            ...mapGetters(['listLanguages','lang', 'itemsPerPage']),
            languages() {
                return this.listLanguages
            },
        },
        mounted() {
            let table = this.$refs.table.$el.querySelector("tbody")
            Sortable.create(table, {
                handle: ".handle",
                onEnd: this.dragReorder
            })
            this.type = this.$route.params.type;
        },
        watch: {
            options: {
                handler() {
                    this.getFaqs();
                },
                deep: false
            }
        },
        methods: {
            dragReorder({newIndex, oldIndex}) {
                const rowSelected = this.faqItems.splice(oldIndex, 1)[0]
                this.faqItems.splice(newIndex, 0, rowSelected)
                this.saveSortFaq()
            },
            async saveSortFaq() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.faqItems && this.faqItems.length > 0) {
                    for (let i in this.faqItems) {
                        if (this.faqItems[i].id !== 'undefined' && this.faqItems[i].id > 0) {
                            formData.append(`sort[${i}]`, this.faqItems[i].id)
                        }
                    }
                }
                await this.$http
                    .post(`admin/faq/sort`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('faq_sorting_updated'))
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('faq_sorting_not_updated'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            editFaq(item) {
                this.$router.push({
                    name: 'faq.edit',
                    params: {
                        id: item.id,
                        type: this.type
                    }
                });
            },
            async getFaqs() {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                } = this.options;
                let params = {};


                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'desc' : 'asc';
                }

                if (this.type) {
                    params.type = this.type;
                }
                if (this.name) {
                    params.name = this.name;
                }
                params.active = this.active
                await this.$http
                    .get("admin/faq", {
                        params: params,
                    })
                    .then(res => {
                        this.faqItems = res.body.data

                    })
                    .catch(err => {
                        this.faqItems = []

                        this.$toastr.error(this.$t('failed_to_get_list_faq_questions'));
                    })
                    .finally(end => {
                        this.loading = false
                    });
            },
            async deleteFaq(item) {
                if (confirm(this.$t('delete_faq_question'))) {
                    this.loading = true;
                    await this.$http
                        .delete(`admin/faq/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('faq_has_been_deleted'));
                            this.getFaqs()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('faq_has_not_been_deleted'));
                            /*
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                            */
                        })
                        .finally(end => {
                            this.loading = false
                        });
                }
            },
        }
    };
</script>
